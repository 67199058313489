import React from 'react';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { getPromotions, PromotionsData } from '@/services/promotions';
import { getAllCampaign, CampaignData } from '@/services/campaign';
import { GameDataType, getHome } from '@/services/home';
import { withServersideNonAuth } from '@/server/authorize';

export type HomeProps = {
  promotionData: PromotionsData
  campaignData: CampaignData[]
  homeData: GameDataType | undefined
};

const Landing = dynamic(() => import('@/components/Pages/Landing'), { ssr: true });

export default function Home(props: HomeProps) {
  return (<Landing {...props} />);
}

export const getServerSideProps: GetServerSideProps = withServersideNonAuth(async ({ locale }) => {
  const [
    promotionData,
    homeData,
    campaignData,
  ] = await Promise.all([
    getPromotions(),
    getHome(),
    getAllCampaign({ types: 'leaderboard' }),
  ]);
  const props = {
    ...await serverSideTranslations(locale || 'th', ['common', 'modal', 'landing', 'promotion', 'system-status', 'lobby', 'search', 'big-win']),
    promotionData,
    campaignData,
    homeData,
  };

  return ({
    props,
  });
});
